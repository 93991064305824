import React, { ChangeEvent, useCallback } from "react";

import dayjs from "dayjs";
import customParse from "dayjs/plugin/customParseFormat";

import {
    Control,
    FieldPath,
    FieldValues,
    PathValue,
    useController,
} from "react-hook-form";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import colors, { blue, darkGrey, white } from "../../../../utils/colors";
import { IconPredefined } from "../../icons/Icon";
import { PredefinedIconType } from "../../icons/Icon/Predefined";
import { withField as withFieldWrapper } from "../Field";
import FormError from "../FormError";
import { InputButton } from "../NewInput";
import ErrorMessage from "./ErroMessage";

dayjs.extend(customParse);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Placeholder = styled.label`
    position: absolute;
    right: 11px;
    left: 11px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    color: ${colors.darkGrey};
    pointer-events: none;
    transition: 0.2s ease all;
`;

export const StyledInput = styled.input`
    width: 100%;
    padding: 15px 10px 5px;
    overflow: hidden;
    font-size: 1em;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.blue.toString()};
    border-color: ${({ theme }) => theme.colors.darkGrey.toString()};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;

    &:placeholder-shown {
        font-weight: normal;
    }

    &:placeholder-shown ~ ${Placeholder} {
        top: 0;
    }

    &:not(:placeholder-shown) ~ ${Placeholder}, &:focus ~ ${Placeholder} {
        top: 2px;
        display: block;
        align-items: flex-start;
        height: 1.5em;
        overflow-x: hidden;
        font-size: 0.75em;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 10px;
`;

export type iInputProps<
    fvs extends FieldValues = FieldValues,
    name extends FieldPath<fvs> = FieldPath<fvs>,
    ctx = any
> = (undefined extends PathValue<fvs, name>
    ? {
          ifNullish: NonNullable<PathValue<fvs, name>>;
          asUndefined?: NonNullable<PathValue<fvs, name>>;
      }
    : Record<never, string>) &
    (null extends PathValue<fvs, name>
        ? {
              ifNullish: NonNullable<PathValue<fvs, name>>;
              asNull?: NonNullable<PathValue<fvs, name>>;
          }
        : Record<never, string>) &
    (PathValue<fvs, name> extends string | number | undefined | null
        ? {
              fullWidth?: boolean;
              name: name;
              type?: string;
              id?: string | null;
              isDisabled?: boolean;
              withField?: boolean;
              maxWidth?: any;
              placeholder: string;
              errors?: any;
              icon?: PredefinedIconType;
              resetField?: any;
              handleClick?: any;
              defaultValue?: PathValue<fvs, name>;
              rules: any;
              control: Control<fvs, ctx>;
          }
        : never);

const Input = <
    fvs extends FieldValues = FieldValues,
    name extends FieldPath<fvs> = FieldPath<fvs>,
    ctx = any
>({
    fullWidth = false,
    type = "text",
    isDisabled = false,
    withField = false,
    maxWidth = undefined,
    placeholder = "",
    errors,
    icon,
    resetField,
    handleClick,
    ...props
}: iInputProps<fvs, name, ctx>) => {
    const { field, fieldState } = useController<fvs, name>(props);

    const { ifNullish, asUndefined, asNull } = props as any as {
        ifNullish?: NonNullable<PathValue<fvs, name>>;
        asUndefined?: NonNullable<PathValue<fvs, name>>;
        asNull?: NonNullable<PathValue<fvs, name>>;
    };

    const value = (field.value ?? ifNullish) as NonNullable<
        PathValue<fvs, name>
    >;

    const onChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            if (value === asUndefined) {
                field.onChange(undefined);
            } else if (value === asNull) {
                field.onChange(null);
            } else {
                field.onChange(value);
            }
        },
        [asUndefined, asNull, field]
    );

    const component = (
        <Wrapper
            css={css`
                max-width: ${maxWidth ?? "none"};
                ${fullWidth ? "width: 100%;" : ""}
            `}
        >
            <Container>
                <StyledInput
                    placeholder=" "
                    autoComplete={
                        [
                            "zipcode",
                            "consumption",
                            "street",
                            "houseNumber",
                            "streetSelectList",
                            "placeSelectList",
                            "houseNumber",
                            "ownerName",
                            "ownerStreet",
                            "ownerStreetNumber",
                            "ownerZipcode",
                            "ownerCity",
                        ].includes(props.name)
                            ? "off"
                            : "on"
                    }
                    disabled={isDisabled}
                    id={props.name as any}
                    {...field}
                    {...{ type, value, onChange, ...props }}
                />
                <Placeholder htmlFor={props.name}>{placeholder}</Placeholder>
                {icon && (
                    <IconContainer>
                        <IconPredefined type={icon} size="20px" color={blue} />
                    </IconContainer>
                )}
                {resetField && (
                    <IconContainer
                        css={css`
                            ${handleClick && "padding-right: 60px;"}
                        `}
                    >
                        <IconPredefined
                            css={css`
                                cursor: pointer;
                            `}
                            type={PredefinedIconType.close}
                            size="20px"
                            color={darkGrey}
                            onClick={() =>
                                resetField(props.name, { defaultValue: "" })
                            }
                        />
                    </IconContainer>
                )}
                {handleClick && (
                    <InputButton onClick={() => handleClick()}>
                        <IconPredefined
                            type={PredefinedIconType.search}
                            size="20px"
                            color={white}
                        />
                    </InputButton>
                )}
            </Container>
            {fieldState.error && (
                <ErrorMessage
                    error={fieldState.error}
                    name={placeholder}
                    rules={props.rules}
                />
            )}
            {errors && Array.isArray(errors) && (
                <FormError gtag={`error:${props.name}`} message={errors[0]} />
            )}
        </Wrapper>
    );

    return withField ? withFieldWrapper(component) : component;
};
export default Input;
